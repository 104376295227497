
    <app-header></app-header>
<div class="hero-section section pt-0">
  <div class="container pt-5">
  </div>
</div>


<div class="row" >
  <div class="col-sm-6  onward-return-tab switch-nav"  [ngClass]="{enable:selected=='onward', disable:selected!='onward','text-muted':selected!='onward'}">
    <div class="row">
      <div class="col-sm-12">
        ONWARD JOURNEY
        <button class="btn" [disabled]="selected!='onward'"  (click)="modify=!modify" style="float: right;margin-right: 5px;background-color:black;border-radius:5px;color: white;"><i class="fa-solid fa-magnifying-glass"></i> Modify</button>
      </div>
   
      <div class="col-sm-12 col-12">
        {{params.source_city}} <i class="fa-solid fa-chevron-right"></i> {{params.dest_city}}  <i class="fas fa-calendar-day mx-2"></i>{{params.travel_date}}
      </div>
      
    </div>
  </div>
  <div class="col-sm-6 switch-nav onward-return-tab"  [ngClass]="{enable:selected=='return', disable:selected!='return','text-muted':selected!='return'}">
    <div class="row" >
      <div class="col-sm-12">
        RETURN JOURNEY
        <button class="btn" [disabled]="selected!='return'" (click)="modify = !modify"  style="float: right;margin-right: 5px;background-color:black;border-radius:5px;color: white;"><i class="fa-solid fa-magnifying-glass"></i> Modify</button>
      </div>
      <div class="col-sm-12 col-12">
         {{params.dest_city}} <i class="fa-solid fa-chevron-right"></i> {{params.source_city}} 
      
        <i class="fas fa-calendar-day mx-2"></i>{{params.return_date}}
      </div>
      

    </div>
  </div>
</div>

 <div class="" [ngSwitch]="selected">
  <div *ngSwitchCase="'onward'">
    <app-onward-trip (proceed)="countinueReturn($event)" [modify]="modify" ></app-onward-trip>
  </div>
  <div *ngSwitchCase="'return'" >
    <app-return [modify]="modify"></app-return>
  </div>
 </div>
 <app-footer></app-footer>