<app-header></app-header>


  <!-- <div class="about-section section container">
    <div class="row">
      <div class="col-12 col-lg-12 mb-3 mb-lg-0">
        <p class="display-5 fw-normal">Kidia Oneexpress</p>
        <p style="text-align: justify;">
          Kidia One Express
          We do provide scheduled daily bus transportation from Dar es Salaam to Moshi, Arusha, Dodoma, Tabora, Kaliua, Uvinza, Kasulu, Kigoma and from these region to Dar es Salaam daily. Our booking Bus Offices are in Dar es Salaam, Moshi, Boma Ng'ombe, Kiboriloni, Arusha, Morogoro, Kasuluand Kigoma.
      </div>
    </div>
  </div> -->
  <div class="about-section section container">
    <div class="row">
       
        <div class="col-md-6">
            <img class="img-fluid" src="../../../assets/img/KidiaOne bus.png" alt="team svg">
        </div>
        <div class="col-md-6">
          <h3>Kidia One Express</h3>
          <p>We provide scheduled daily bus transportation from Dar es Salaam Tanzania to Moshi Tanzania, Arusha Tanzania, Namanga Border and Nairobi Kenya and from these Countries and regions to Dar es Salaam Tanzania daily. Our booking Bus Offices are in Dar es Salaam Tanzania, Moshi Tanzania, Arusha Tanzania, Namanga Border and Nairobi Kenya.
          </p>
      </div>
    </div>
</div>
  <app-footer></app-footer>
  