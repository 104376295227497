<main>
  <app-header></app-header>

    <!-- <div class="hero-section section pt-0">
      <div class="container pt-5">
      </div>
      <div class="container h-100 text-white">
        <p class="display-6 fw-bold text-center pt-5 an-1"> Hi, Book,  <i class="fa-solid fa-angles-right"></i>  Pay <i class="fa-solid fa-angles-right"></i> & Travel with Us</p>
      </div>
    </div> -->

   <div >
    <div #carousel class="carousel slide" data-bs-ride="carousel" data-bs-config='{"wrap":true}'>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/img/bg2.png" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/bg1.png" class="d-block w-100" alt="...">

        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/bg3.png" class="d-block w-100" alt="...">

        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/bg4.png" class="d-block w-100" alt="...">

        </div>
      </div>
    </div>
   </div>
    <div class="section container">
      <div class="card border-0 shadow p-lg-4" >
        <div class="card-body">
          
          <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" >
          <div class="row">
           
              <div class="col-md-3">
                  <mat-form-field appearance="outline">
                      <mat-label>From</mat-label>
                      <input matInput  formControlName="sourceCity"    [matAutocomplete]="auto" placeholder="From">
                      <mat-autocomplete   #auto="matAutocomplete">
                        <mat-option     *ngFor="let option of filteredOptions | async" [value]="option">
                          {{option.city_name}}
                        </mat-option>
                      </mat-autocomplete>
                  </mat-form-field>
              </div>
              <div class="col-md-3">
                  <mat-form-field appearance="outline">
                      <mat-label>Destination</mat-label>
                      <input matInput  formControlName="destCity"  [matAutocomplete]="dest"  placeholder="Destination">
                      <mat-autocomplete   #dest="matAutocomplete">
                        <mat-option  *ngFor="let option of destOptions | async" [value]="option">
                          {{option.city_name}}
                        </mat-option>
                      </mat-autocomplete>
                  </mat-form-field>
              </div>
              <div class="col-md-3">
                  <mat-form-field appearance="outline">
                      <mat-label>Date</mat-label>
                      <input matInput [min]="date"  (ngModelChange)="setDate(f.date.value)"  formControlName="date" [matDatepicker]="picker1" placeholder="Date">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col-md-3">
                  <mat-form-field appearance="outline">
                      <mat-label>Return Date</mat-label>
                      <input matInput  formControlName="returnDate" [min]="return_min" [matDatepicker]="picker" placeholder="Return Date">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
              </div>
          
              <div class="col-md-12">
                  <button class="btn pull-right" style="background-color: rgb(230,23,115);color: white;"> <i class="fa-solid fa-search"></i> Search</button>
              </div>
          </div>
          </form>
        </div>
      </div>
    </div>
   
    <div class="container" style="bottom: 3rem; position: relative; margin-top:40px;">
      <div>
        <div  >
          <div class="feature-custom text-center hidden-xs ng-scope" style="background-color: #0e6ba8;">
            <div class="col-ld-3 col-md-3 col-sm-3 text-center" style="padding: 0;border-right:solid 1px white"><div class="feature-icon"><img src="../../assets/img/display1.png" style="width:60px"></div><h3 style="font-size: 16px;font-weight:bold;color:white ; ">VIP Treatment</h3></div>
          <div class="col-ld-3 col-md-3 col-sm-3 text-center" style="padding: 0;border-right:solid 1px white"><div class="feature-icon"><img src="../../assets/img/display2.png" style="width:60px"></div><h3 style="font-size: 16px;font-weight:bold;color:white ; ">On Board Wifi Available</h3></div>
          <div class="col-ld-3 col-md-3 col-sm-3 text-center" style="padding: 0;border-right:solid 1px white"><div class="feature-icon"><img src="../../assets/img/display3.png" style="width:60px"></div><h3 style="font-size: 16px;font-weight:bold;color:white ; ">Power Outlets</h3></div>
          <div class="col-ld-3 col-md-3 col-sm-3 text-center" style="padding: 0;"><div class="feature-icon"><img src="../../assets/img/display4.png" style="width:60px"></div><h3 style="font-size: 16px;font-weight:bold;color:white">Extra Legroom</h3></div>
          </div>
          
  
         
        </div>
      </div>
    </div>

    <div class="container">
      <div class="section-body">
        <div class="row row-cols-1 row-cols-md-2 g-2">
          <div class="col">
            <img src="../../../assets/img/kidiaslide1.png" class="d-block w-100">
          </div>
          <div class="col">
            <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../assets/img/kidiaslide2.png" class="d-block w-100">
                </div>
                <div class="carousel-item">
                  <img src="../../../assets/img/kidiaslide3.png" class="d-block w-100">
                </div>
               
               
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="stats-section section container">
      <div class="row align-items-stretch h-100">
       
        <div class="col-12 col-lg-12">
          <h2 align="center">Why  Us</h2>
          <div class="section-body">
            <div class="row g-4 row-cols-1 row-cols-lg-3">
              <div class="col">
                <div class="card border-0 shadow">
                  <div class="card-body">
                    <img src="assets/img/save.png"  class="center" alt="" style="height:165px;">
                    <h3 class="fs-4">Save</h3>
                    <p>Pay less when using our new Mobile App. You can save up to 25% of your Promo Cash balance in a single purchase.</p>
                  </div>
                 
                </div>
              </div>
              <div class="col">
                <div class="card border-0 shadow">
                  <div class="card-body">
                    <img src="assets/img/routes.png" class="center" alt="">
                    <h3 class="fs-4">Multiple Routes                    </h3>
                    <p>We cover multiple routes and the widest connectivity in Kenya,</p>
                  </div>
                 
                </div>
              </div>
              
              <div class="col">
                <div class="card border-0 shadow">
                  <div class="card-body">
                    <img src="assets/img/credits.png" class="center" alt="">
                    <h3 class="fs-4">Earn Credits</h3>
                    <p>Receive more credit during promotional periods which will be added into your Promo Cash account.</p>
                  </div>
                 
                </div>
              </div>
             
        
          
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="display-6 fw-normal">Our Services</div>
      <div class="section-body">
        <div class="row row-cols-1 row-cols-md-3 g-4">
          <div class="col">
            <div class="card border-0 shadow">
              <img src="../../../assets/img/delivery.png" alt="...">
              <div class="card-img-overlay">
                <span class="badge text-bg-light">Parcel Delivery</span>
              </div>
              <div class="card-body">
                <h5 class="card-title">Parcel Delivery</h5>
                <p class="card-text">We deliver parcels to all major towns in the country.</p>
              </div>
             
            </div>
          </div>
          <div class="col">
            <div class="card border-0 shadow">
              <img src="../../../assets/img/fleet.png" alt="..." style="height:250px;">

              <div class="card-img-overlay">
                <span class="badge text-bg-light">Fleet Services</span>
              </div>
              <div class="card-body">
                <h5 class="card-title">Fleet</h5>
                <p class="card-text">Our has VIP Treatment ,Onboard Wifi Available,Power Outlets and Extra Legroom</p>
              </div>
             
            </div>
          </div>
          <div class="col">
            <div class="card border-0 shadow">
              <img src="../../../assets/img/map.png" alt="..." style="height:250px;">
              <div class="card-img-overlay">
                <span class="badge text-bg-light">Routes</span>
              </div>
              <div class="card-body">
                <h5 class="card-title">Routes</h5>
                <p class="card-text">We operate in Tanzania,Kenya and we provide the best Customer Service</p>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>

    
 
      <div class="blog-section section container" >
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="row">
              <div class="col-sm-6">
                <img src="../../../assets/img/playstore.png" alt="" style="width: 100%;object-fit: cover;">
              </div>
              <div class="col-sm-6">
                <img src="../../../assets/img/appstore.png" alt="" style="width: 100%;object-fit: cover;">
              </div>
            </div>
          </div>
          <div class="col-sm-8 col-8">
            <div class="row">
              <div class="col-sm-6"></div>
              <div class="col-sm-4">
                <img src="../../../assets/img/boss.png" alt="" style="width: 100%;object-fit: cover;">
              </div>
              <div class="col-sm-2">
                <img src="../../../assets/img/voltic.svg" alt="" style="width: 100%;object-fit: cover;">
              </div>
            </div>
          </div>
         
        </div>
      </div>

    
  
  </main>
  <app-footer></app-footer>