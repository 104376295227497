<app-header></app-header>


<div class="section container">
    <div class="row">
        <div class="col-sm-8 offset-lg-2">
            <div class="row"> 
                <div class="col-sm-2 col-4">
                    <img src="assets/img/female.png" *ngIf="user.gender =='female'"  width="100" height="100" alt="">
                    <img src="assets/img/user.png" *ngIf="user.gender =='male'"  width="100" height="100" alt="">

                </div>
                <div class="col-sm-4 col-8">
                <div class="row">
                    <div class="col-sm-12">
                        Name: <span style="font-weight:500;" class="details">{{user.name}}</span>
                    </div>
                    <div class="col-sm-12">
                        Contact: <span style="font-weight:500;" class="details">{{user.country_code}}{{user.phone}}</span>
                    </div>
                    <div class="col-sm-12">
                        Age: <span style="font-weight:500;" class="details">{{user.age}}</span>
                    </div>
                    <div class="col-sm-12">
                        <span style="font-weight:600" class="text-success">Wallet</span>: <span style="font-weight:900;">0.00</span>
                    </div>
                </div>
                
                </div>


                <div class="col-sm-12">
                  <mat-tab-group>
                    <mat-tab>
                      <ng-template mat-tab-label>
                        Profile
                      </ng-template>
                      <form [formGroup]="registerForm" style="margin-top:10px;">
                        <div class="row container-small">
                          <div class="col-sm-12">
                            <mat-form-field appearance="outline">
                              <mat-label>Name</mat-label>
                              <input matInput   formControlName="name"    placeholder="Name">
                          </mat-form-field>
                          </div>
                          <div class="col-sm-4">
                            <mat-form-field appearance="outline">
                              <mat-label>Code</mat-label>
                              <mat-select formControlName="country_code" >
                                <mat-option *ngFor="let code of codes" [value]="code">
                                  {{code}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-8">
                            <mat-form-field appearance="outline">
                              <mat-label>Phone</mat-label>
                              <input matInput  formControlName="phone"    placeholder="Phone">
                          </mat-form-field>
                          </div>
                          <div class="col-sm-6">
                            <mat-form-field appearance="outline">
                              <mat-label>Date of Birth</mat-label>
                              <input matInput  formControlName="dob" [matDatepicker]="picker1" placeholder="Date">
                              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                              <mat-datepicker #picker1></mat-datepicker>
                          </mat-form-field>
                          </div>
                          <div class="col-sm-6">
                            <mat-form-field appearance="outline">
                              <mat-label>Gender</mat-label>
                              <mat-select formControlName="gender" >
                                <mat-option  value="female">
                                 Female
                                </mat-option>
                                <mat-option  value="male">
                                  Male
                                 </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                         
                          
                          <div class="col-sm-12">
                            <button class="btn btn-primary save-btn pull-right">Save Changes</button>
                          </div>
                        </div>
                        </form>
                    </mat-tab>
                  
                    <mat-tab>
                      <ng-template mat-tab-label>
                        My Trips
                      </ng-template>
                   
                      <table mat-table [dataSource]="trips" class="mat-elevation-z8">

                  
                        <ng-container matColumnDef="position">
                          <th mat-header-cell *matHeaderCellDef> S/N </th>
                          <td mat-cell *matCellDef="let element"> {{element.position}} </td>
                        </ng-container>
                      
                        <ng-container matColumnDef="date">
                          <th mat-header-cell *matHeaderCellDef>DATE</th>
                          <td mat-cell *matCellDef="let element"> {{element.date}} </td>
                        </ng-container>
                
                        <ng-container matColumnDef="route">
                          <th mat-header-cell *matHeaderCellDef>ROUTE</th>
                          <td mat-cell *matCellDef="let element"> {{element.route}} </td>
                        </ng-container>
                  
                        <ng-container matColumnDef="boarding">
                          <th mat-header-cell *matHeaderCellDef>BOARDING</th>
                          <td mat-cell *matCellDef="let element"> {{element.boarding} </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <th mat-header-cell *matHeaderCellDef> STATUS</th>
                          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                        </ng-container>
                      
                        <tr mat-header-row *matHeaderRowDef="tripColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns:tripColumns;"></tr>
                      </table>
                      

                    </mat-tab>
                  
                    <mat-tab>
                      <ng-template mat-tab-label>
                        Wallet
                      </ng-template>


                      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                  
                        <ng-container matColumnDef="position">
                          <th mat-header-cell *matHeaderCellDef> S/N </th>
                          <td mat-cell *matCellDef="let element"> {{element.position}} </td>
                        </ng-container>
                      
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef> NAME </th>
                          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>
                
                        <ng-container matColumnDef="amount">
                          <th mat-header-cell *matHeaderCellDef>AMOUNT</th>
                          <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
                        </ng-container>
                  
                        <ng-container matColumnDef="comment">
                          <th mat-header-cell *matHeaderCellDef>COMMENT</th>
                          <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <th mat-header-cell *matHeaderCellDef> STATUS</th>
                          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                        </ng-container>
                      
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>

                     
                    </mat-tab>

                    <mat-tab>
                      <ng-template mat-tab-label>
                        Change Password
                      </ng-template>
                      <form [formGroup]="passwordForm" (ngSubmit)="changePassword()" style="margin-top: 10px;">
                          <div class="col-sm-12">
                            <mat-form-field appearance="outline">
                              <mat-label>Current Password</mat-label>
                              <input matInput type="password"  formControlName="current"  placeholder=" Current Password">
                          </mat-form-field>
                          </div>
                          <div class="col-sm-12">
                            <mat-form-field appearance="outline">
                              <mat-label>New Password</mat-label>
                              <input matInput type="password"  formControlName="password"  placeholder="New Password">
                          </mat-form-field>
                          </div>
                          <div class="col-sm-12">
                            <mat-form-field appearance="outline">
                              <mat-label>Confirm Password</mat-label>
                              <input matInput type="password"  formControlName="confirm"  placeholder="Confirm Password">
                          </mat-form-field>
                          </div>
                          <div class="col-sm-12 pb-2" >
                            <button class="btn save-btn" [disabled]="passwordForm.invalid" style="float: right;">Update Password</button>
                          </div>
                          
                        </form>
                       
                    </mat-tab>
                    
                  </mat-tab-group>
                </div>
           
            </div>
        </div>
    </div>
   
    
  </div>