<app-header></app-header>
<div class="hero-section section pt-0">
    <div class="container pt-5">
    </div>
   
  </div>



<div class="blog-section section container">
    <h1  style="text-align: center;font-size: 90px;" class="text-primary"> <i class="fa-solid fa-receipt"></i></h1>
    <h4 style="text-align: center">Print Ticket</h4>
      <div class="row">
        <div class="col-md-4 offset-md-4">
            <div class="row">
                <div class="col-sm-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Ticket No</mat-label>
                        <input matInput placeholder="Ticket No">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Country Code</mat-label>
                        <input matInput placeholder="Contry Code">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Phone </mat-label>
                        <input matInput placeholder="Phone">
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <button class="btn btn-success"> <i class="fa-solid fa-print"></i> Print</button>
                </div>

            </div>
        </div>

      </div>
    
  </div>
  <app-footer></app-footer>