<!-- <footer class="bg-dark text-white">
  <div class="container">
    <footer class="pt-5 pb-3">
      <div class="row">
        <div class="col-12 col-md-3 mb-3">
          <div class="d-flex align-items-end gap-2 mb-3">
            <img src="assets/img/logo.png" height="50px" width="171px" alt="">
          </div>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <h5>Quick Links</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-light">Home | About us | Services</a></li>
         
          </ul>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <h5>Contact info</h5>
          <ul class="nav flex-column">
            
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-light">
                 <i class="fas fa-envelope fa-fw me-2"></i>coast@coastbus.com

              </a>
            </li>
          
          </ul>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <form>
            <h5>Subscribe to our newsletter</h5>
            <div class="d-flex flex-column flex-sm-row w-100 gap-2">
              <label for="newsletter1" class="visually-hidden">Email address</label>
              <input id="newsletter1" type="text" class="form-control" placeholder="Email address">
              <button class="btn btn-primary" type="button">Subscribe</button>
            </div>
          </form>
        </div>
      </div>

      <div class="d-flex flex-column flex-sm-row justify-content-between pt-4 border-top">
        <p>© 2022 Company, Inc. All rights reserved.</p>
        <ul class="list-unstyled d-flex">
          <li class="ms-3">
            <a class="link-light" href="#">
              <i class="fab fa-facebook-f fa-fw"></i>
            </a>
          </li>
          <li class="ms-3">
            <a class="link-light" href="#">
              <i class="fab fa-twitter fa-fw"></i>
            </a>
          </li>
          <li class="ms-3">
            <a class="link-light" href="#">
              <i class="fab fa-linkedin-in fa-fw"></i>
            </a>
          </li>
          <li class="ms-3">
            <a class="link-light" href="#">
              <i class="fab fa-instagram fa-fw"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</footer> -->


<div style="background-color: #0e6ba8;color:white;">
  <div class="container-fluid top" >
      <div class="container">           
          <div class="row mt-3">
              <div class="col col-md-6 col-lg-3">
                  <p style="font-weight: bold;">Head Office
                    KidiaOneExpress</p>
                  <small>Tanzania,Kenya</small><br>
                  <small>Email Us : info@kidiaoneexpress.com</small> <br>
                  <small>Head Office: (+255) 784377027/(+255) 693440568</small><br>
                  <small>Shekilango: (+255) 757575740</small><br>
                  <small>Basihaya: (+255) 748700005</small>
                  <small>Kariakoo: (+255) 747000019</small><br>
                  <small>Basihaya: (+255) 748700005</small>
                  <small>Shekilango: (+255) 757575740</small><br>
                  <small>Basihaya: (+255) 748700005</small>
                  <small>Shekilango: (+255) 757575740</small><br>
                  <small>Basihaya: (+255) 748700005</small>
                  <small>Shekilango: (+255) 757575740</small><br>
                  <small>Basihaya: (+255) 748700005</small>
              </div>
              <div class="col col-md-6 col-lg-3">
                  <p style="font-weight: bold;">Kidia One Express</p>

                  <small>Shekilango Dsm +255757575740 <br>
                    Kariakoo mtaa wa Msimbazi na Nyamwe Dsm +255747000019 <br>
                    Boko Basihaya Dsm +255748700005 <br>
                    Moshi opp puma petroli station,Kilimanjaro +255744636365 <br>
                    Arusha, NhcMakao mapya +255757575786 <br>
                    Dodoma Standi +255762232323 <br>
                    Nyegezi ,Mwanza +255742228383 <br>
                    Magufuli Bius Terminal Room No. A12 +255748700004 <br>
                  </small>
              </div>
              <div class="col col-md-6 col-lg-3">
                  <p style="font-weight: bold;">Kidia One Hotel</p>
                 <small>Plot No 80, Block D,<br> Msamvu Round about Morogoro <br>Phone:+255658565600 <br>hotel@kidiaone.co.tz <br>Map | Contact Us</small>
              </div>
              <div class="col col-md-6 col-lg-3">
                  <p style="font-weight: bold;">Kidia One Trucks</p>
                  <small>P. O. Box 12981 Dar es Salaam <br> Phone: 0719766088 or 0753683480 <br>info@kidiaone.co.tz 
                    <br>info@kidiaonepress.co.tz  <br>
                    Contact Us</small>
              </div>
          </div>
      </div>
  </div>
  <div class="row text-center top p-1" style="width: 100vw;">
      <hr>
      <small style="font-size: small;">All rights reserved</small>
  </div>    
</div>